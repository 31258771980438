//
// user.js
// Use this to write your custom JS
//
import { Modal } from 'bootstrap'

var d=document,w="https://tally.so/widgets/embed.js",v=function(){"undefined"!=typeof Tally?Tally.loadEmbeds():d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((function(e){e.src=e.dataset.tallySrc}))};if(d.querySelector('script[src="'+w+'"]'))v();else{var s=d.createElement("script");s.src=w,s.onload=v,s.onerror=v,d.body.appendChild(s);}

window.addEventListener('load', function() {

    
    // your code here
    var exampleModalEl = document.getElementById('exampleModal');
    var exampleModal = new Modal(document.getElementById('exampleModal'));
    var signupEmail = document.querySelector('.signup-email');

    exampleModalEl && exampleModalEl.querySelector('.modal-body').insertAdjacentHTML('beforeend', `<iframe class="tally-form" data-tally-src="https://tally.so/embed/m68XPk?hideTitle=1&transparentBackground=1&dynamicHeight=1" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="Request access to Published"></iframe>`);
    Tally && Tally.loadEmbeds();

    // on pressing enter, submit the form
    signupEmail && signupEmail.addEventListener('keyup', function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleEmailSubmit();
        }
    });

    // on clicking the submit button, submit the form
    document.querySelector('#subscriptionButton') && document.querySelector('#subscriptionButton').addEventListener('click', function(event) {
        event.preventDefault();
        handleEmailSubmit();
    });

    function handleEmailSubmit() {
        // check if email is valid regex
        if (signupEmail.value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
            exampleModalEl.querySelector('.modal-body').innerHTML = '';
            exampleModalEl.querySelector('.modal-body').insertAdjacentHTML('beforeend', `<iframe class="tally-form" data-tally-src="https://tally.so/embed/m68XPk?hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${signupEmail.value}" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="Request access to Published"></iframe>`);
            Tally.loadEmbeds();
            exampleModal.show();
        } else {
            alert('Please enter a valid email address');
        }
    }

    // for every btn-cta-tally, add a click event listener
    document.querySelectorAll('.btn-cta-tally').forEach(function(element) {
        // on clicking the button, show the modal
        element.addEventListener('click', function(event) {
            event.preventDefault();
            exampleModal.show();
        });
    });

}, false);